<template>
  <section ref="section" class="-visibility-auto dual-tile">
    <Observer name="dual-enter" @enter="enter">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 tile" v-for="bloc in tileItems" :key="bloc.title">
            <div class="bloc" :class="[ item && item.animated ? '-animated': '', bloc.textColor === 'white' ? '-white' : '' ]">
              <router-link :to="localizedUrl + bloc.ctaLink" class="bloc-link" v-if="bloc.picture && bloc.ctaLink && bloc.ctaText">
                <lazy-image v-if="bloc.picture" class-name="bloc-picture__image" :item="bloc.picture" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
              </router-link>
              <h3 class="subtitle" v-if="bloc.headline" v-text="bloc.headline"></h3>
              <h2 class="title" v-if="bloc.title" v-text="bloc.title"></h2>
              <router-link v-if="bloc.ctaLink && bloc.ctaText" :to="localizedUrl + bloc.ctaLink" class="link-light" :class="{ '-white': bloc.textColor === 'white' }" v-text="bloc.ctaText"></router-link>
            </div>
          </div>
        </div>
      </div>
    </Observer>
  </section>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import LazyImage from '../../components/utils/LazyImage'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'

export default {
  mixins: [ReadyHook],
  name: 'HRDualTile',
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  components: {
    LazyImage
  },
  data() {
    return {
      appeared: false
    }
  },
  computed: {
    localizedUrl() {
      const { url } = currentStoreView()
      return url || ''
    },
    pictureSizes() {
      return '(min-width: 768px) 50vw, 100vw'
    },
    tileItems() {
      return this.items.map(item => {
        let obj = {
          title: item.title,
          headline: item.headline,
          ctaText: item.ctaText,
          ctaLink: item.ctaLink,
          textColor: item.textColor,
          picture: {
            url: item.image.url,
            lowDefinitionUrl: ImageManager.getLowDefinition(item.image.url, 200, 1.54),
            srcset: ImageManager.getSrcSet(item.image.url, 1400, 300, 100, 1.54),
            alt: item.image.alt || '',
            width: item.image.width,
            height: item.image.height
          }
          // pictureMobile: {
          //   url: item.image,
          //   lowDefinitionUrl: ImageManager.getLowDefinition(item.imageMobile, 200, 1.222),
          //   srcset: ImageManager.getSrcSet(item.imageMobile, 1400, 300, 100, 1.222),
          //   alt: item.imageMobile.alt || '',
          //   width: item.imageMobile.width,
          //   height: item.imageMobile.height
          // }
        }
        return obj
      })
    }
  },
  methods: {
    enter() {
      if (!this.appeared) {
        this.$refs.section.classList.remove('-visibility-auto')
        this.appeared = true
      }
    },
  },
  created() {},
  mounted() {},
  destroyed() {}
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  position: relative;
  margin-bottom: 8rem;
  @include bp(max md) {
    margin-bottom: 2rem;
  }
}

.tile {
  &.col-md-6 {
    &:first-child {
      padding-right: 4rem;
      @include bp(max md) {
        padding-right: 1.5rem;
        margin-bottom: 2rem;
      }
    }
    &:last-child {
      padding-left: 4rem;
      @include bp(max md) {
        padding-left: 1.5rem;
      }
    }
  }
}

.obs-dual-enter {
  .bloc {
    .subtitle {
      opacity: 0;
    }
    .title {
      opacity: 0;
      transform: translateY(6rem);
    }

    a, button {
      &:not(.bloc-link) {
        transform: translateY(4rem);
        opacity: 0;
      }
    }
  }
}
.bloc {
  position: relative;
  overflow: hidden;
  height: calc(50vw * 1.36);
  max-height: 80rem;
  padding: 6rem 5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;

  &:before {
    display: block;
    content: '';
    position: absolute;
    z-index: 15;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.1);
    pointer-events: none;
  }

  &.-white {
    &:before {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  @include bp(max md) {
    padding: 2rem;
    //min-height: calc(100vw * 1.36);
    min-height: calc(100vw * 0.84);
  }

  .subtitle {
    position: relative;
    z-index: 20;
    font-weight: normal;
    color: var(--zenith-black);
    font-size: em(14);
    letter-spacing: em(2, 14);
    margin-bottom: 0.7rem;
    text-transform: uppercase;
    opacity: 1;
    @include appearTitle(800ms);

    @include bp(max md) {
      pointer-events: none;
    }

  }

  .title {
    display: block;
    position: relative;
    z-index: 20;
    font-size: em(38);
    letter-spacing: 0;
    font-weight: normal;
    color: var(--zenith-black);
    margin-bottom: 3rem;
    opacity: 1;
    transform: translateY(0);
    @include appearTitle(900ms);

    @include bp(max md) {
      font-size: em(26);
      margin-bottom: 2rem;
      pointer-events: none;
    }
  }

  button,
  a {
    &:not(.bloc-link) {
      position: relative;
      z-index: 20;
      @include appear(1200ms);
    }
  }

  &.-white {
    .subtitle,
    .title {
      color: var(--zenith-white);
    }
  }

  &-link {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  &-picture {
    &__image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 100%;
      height: 100%;
      transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);

      /deep/ img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &.-animated {
    &:hover, &:focus, &:active  {
      .bloc-picture__image {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }
}
</style>
